import React from 'react';

const Heart = (props) => (
    <svg
        viewBox="0 0 20 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M14.4989 0.600098C13.4571 0.600098 12.5019 0.927326 11.6599 1.57271C10.8527 2.19145 10.3153 2.97953 9.99893 3.55259C9.68254 2.97949 9.14513 2.19145 8.33794 1.57271C7.49599 0.927326 6.54079 0.600098 5.49893 0.600098C2.59148 0.600098 0.398926 2.95719 0.398926 6.08292C0.398926 9.45979 3.13429 11.7702 7.27526 15.2678C7.97847 15.8618 8.77553 16.5351 9.60398 17.2531C9.71318 17.3479 9.85343 17.4001 9.99893 17.4001C10.1444 17.4001 10.2847 17.3479 10.3939 17.2532C11.2224 16.535 12.0194 15.8618 12.723 15.2675C16.8636 11.7702 19.5989 9.45979 19.5989 6.08292C19.5989 2.95719 17.4064 0.600098 14.4989 0.600098Z" fill={props.fill} fillOpacity={props?.fillOpacity || 0.5} />
    </svg>
);

export default Heart;
